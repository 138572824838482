/* src/components/Home.css */

.home {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    grid-template-rows: 1fr 1fr;    /* Two equal rows */
    height: 100vh;                  /* Full viewport height */
    width: 100vw;                   /* Full viewport width */
    gap: 0;                         /* No space between sections for uniform look */
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;        /* Ensure background covers section */
    background-position: center;    /* Center background image */
    color: white;
    font-size: 1.5em;
    text-shadow: 1px 1px 2px black;
    padding: 20px;
    height: 100%;                  /* Ensure section fills grid cell */
}
