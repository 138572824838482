.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#gif-of-the-day {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 50%;*/
  /*!*background-color: white;*!*/
  /*width: 150px;*/
  /*!*height: 150px;*!*/
  /*!*border-radius: 50%;*!*/
  /*margin-left: -75px;*/
  /*!*margin-top: -75px;*!*/
}

#gif-of-the-day img {
  /*border-radius: 10%;*/
}

.overlay {
  position: fixed;
  top: 135px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Keeps it on top */
}

/* Round container */
.gif-container {
  width: 150px;
  height: 150px;
  /*background-color: white;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;*/
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}

.gif-container p {

}

.main-text-container {
  width: 100%;
  /*height: 100vh;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-text-container h1 {
  font-size: 25px;
  text-transform: uppercase;
  font-family: 'Gambetta', serif;
  letter-spacing: -3px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  /*color: PaleGoldenRod;*/
  color: whitesmoke;
  text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em purple;
  outline: none;
  text-align: center;
  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  transform:rotate(-30deg);
}

.main-text-container h1:hover {
  font-variation-settings: "wght" 582;
  letter-spacing: 1px;
}

.main-text-container p {
  font-size: 1.2em;
  /*line-height: 150%;*/
  text-align: center;
  color: MintCream;
  letter-spacing: .5px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .gif-container {
    width: 120px;
    /*height: 120px;*/
  }
}

/* GIF styling */
.gif {
  width: 80%;
  height: auto;
  border-radius: 5%;
}

/* Text styling */
.text {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.combo-countdown {
  font-size: 16px;
}

.combo-countdown i {
  position: absolute;
  margin-top: -54px;
  text-align: right;
  font-size: 10px;
}


#fact-of-the-day {
  width: 100%;
  background-color: #222333;
  color: white;
  text-align: center;
  padding: 2px;
  font-size: 14px;
  /*font-weight: bold;*/
}